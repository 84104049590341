const userModule = {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    loginUser({ commit }, user) {
      commit('setUser', user);
    },
    logoutUser({ commit }) {
      commit('clearUser');
    },
  },
  getters: {
    isLoggedIn: state => {
      return state.user !== null;
    },
    getUser: state => {
      return state.user;
    },
  },
};

export default userModule;
