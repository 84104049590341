/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-10 14:51:23
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-01-21 17:35:31
 * @FilePath: /监管/jianguan/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-10 14:51:23
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-01-21 11:03:15
 * @FilePath: /监管/jianguan/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
import ContentView from "../views/ContentView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      title: "登陆",
      showCommon: false,
    },
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/404",
    name: "NotFound",
    meta: {
      title: "Page not found",
      isLogin: false,
    },
    component: () => import("@/views/NotFound.vue"),
  },
  //数据展示
  {
    path: "/data_presentation",
    name: "data_presentation",
    meta: {
      title: "数据展示",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/instrument_cluster",
        name: "instrument_cluster",
        meta: {
          title: "仪表盘",
          showCommon: true,
        },
        component: () =>
          import("../views/data_presentation/InstrumentCluster.vue"),
      },
      {
        path: "/business_handling",
        name: "business_handling",
        meta: {
          title: "业务办理",
          showCommon: true,
        },
        component: () => import("../views/workbench/BusinessHandling.vue"),
      },
      {
        path: "/business_service_evaluation",
        name: "business_service_evaluation",
        meta: {
          title: "服务评价",
          showCommon: true,
        },
        component: () => import("../views/workbench/ServiceEvaluation.vue"),
      },
    ],
  },
  //分析工具
  {
    path: "/analysis_tools",
    name: "analysis_tools",
    meta: {
      title: "分析工具",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/trend_analysis",
        name: "trend_analysis",
        meta: {
          title: "趋势分析",
          showCommon: true,
        },
        component: () => import("../views/analysis_tools/TrendAnalysis.vue"),
      },
      {
        path: "/comparative_analysis",
        name: "comparative_analysis",
        meta: {
          title: "比较分析",
          showCommon: true,
        },
        component: () =>
          import("../views/analysis_tools/ComparativeAnalysis.vue"),
      },
      {
        path: "/prediction_model",
        name: "prediction_model",
        meta: {
          title: "预测模型",
          showCommon: true,
        },
        component: () => import("../views/analysis_tools/PredictionModel.vue"),
      },
    ],
  },
  //交互操作
  {
    path: "/interactive_operation",
    name: "interactive_operation",
    meta: {
      title: "交互操作",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/enterprise_historical_data",
        name: "enterprise_historical_data",
        meta: {
          title: "企业历史数据",
          showCommon: true,
        },
        component: () =>
          import("../views/interactive_operation/EnterpriseHistoricalData.vue"),
      },
      {
        path: "/enterprise_information_dynamic_update",
        name: "enterprise_information_dynamic_update",
        meta: {
          title: "企业信息动态更新",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/interactive_operation/EnterpriseInformationDynamicUpdate.vue"
          ),
      },
      {
        path: "/business_service_evaluation",
        name: "business_service_evaluation",
        meta: {
          title: "服务评价",
          showCommon: true,
        },
        component: () => import("../views/workbench/ServiceEvaluation.vue"),
      },
    ],
  },
  //工作台
  {
    path: "/workbench",
    name: "workbench",
    meta: {
      title: "工作台",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/business_consulting",
        name: "business_consulting",
        meta: {
          title: "业务咨询",
          showCommon: true,
        },
        component: () => import("../views/workbench/BusinessConsulting.vue"),
      },
      {
        path: "/business_handling",
        name: "business_handling",
        meta: {
          title: "业务办理",
          showCommon: true,
        },
        component: () => import("../views/workbench/BusinessHandling.vue"),
      },
      {
        path: "/business_service_evaluation",
        name: "business_service_evaluation",
        meta: {
          title: "服务评价",
          showCommon: true,
        },
        component: () => import("../views/workbench/ServiceEvaluation.vue"),
      },
    ],
  },

  //风险管理
  {
    path: "/risk",
    name: "risk",
    meta: {
      title: "风险管理",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/risk_monitoring",
        name: "risk_monitoring",
        meta: {
          title: "风险监测",
          showCommon: true,
        },
        component: () => import("../views/risk/RiskMonitoring.vue"),
      },
      {
        path: "/risk_assessment_report",
        name: "risk_assessment_report",
        meta: {
          title: "风险评估报告",
          showCommon: true,
        },
        component: () => import("../views/risk/RiskAssessmentReport.vue"),
      },
      {
        path: "/safety_improvements",
        name: "safety_improvements",
        meta: {
          title: "安全管理改进",
          showCommon: true,
        },
        component: () => import("../views/risk/SafetyImprovements.vue"),
      },
      {
        path: "/risk_control",
        name: "risk_control",
        meta: {
          title: "风险控制",
          showCommon: true,
        },
        component: () => import("../views/risk/RiskControl.vue"),
      },
      {
        path: "/risk_prevention",
        name: "risk_prevention",
        meta: {
          title: "风险预防",
          showCommon: true,
        },
        component: () => import("../views/risk/RiskPrevention.vue"),
      },
    ],
  },

  //中介管理
  {
    path: "/intermediary",
    name: "intermediary",
    meta: {
      title: "中介管理",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/intermediary_reg",
        name: "intermediary_reg",
        meta: {
          title: "中介注册",
          showCommon: true,
        },
        component: () => import("../views/intermediary/IntermediaryReg.vue"),
      },
      {
        path: "/intermediary_audit",
        name: "intermediary_audit",
        meta: {
          title: "中介审核",
          showCommon: true,
        },
        component: () => import("../views/intermediary/IntermediaryAudit.vue"),
      },
      {
        path: "/intermediary_rating",
        name: "intermediary_rating",
        meta: {
          title: "中介评级",
          showCommon: true,
        },
        component: () => import("../views/intermediary/IntermediaryRating.vue"),
      },
      {
        path: "/mediation_selection",
        name: "mediation_selection",
        meta: {
          title: "中介选择",
          showCommon: true,
        },
        component: () => import("../views/intermediary/MediationSelection.vue"),
      },
      {
        path: "/insurance_transaction_processing",
        name: "insurance_transaction_processing",
        meta: {
          title: "保险事务处理",
          showCommon: true,
        },
        component: () =>
          import("../views/intermediary/InsuranceTransactionProcessing.vue"),
      },

      {
        path: "/service_evaluation",
        name: "service_evaluation",
        meta: {
          title: "服务评价",
          showCommon: true,
        },
        component: () => import("../views/intermediary/ServiceEvaluation.vue"),
      },
    ],
  },

  //企业管理
  {
    path: "/enterprise",
    name: "enterprise",
    meta: {
      title: "企业管理",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/enterprise_reg",
        name: "enterprise_reg",
        meta: {
          title: "企业注册",
          showCommon: true,
        },
        component: () => import("../views/enterprise/EnterpriseReg.vue"),
      },
      {
        path: "/enterprise_reg_add",
        name: "enterprise_reg_add",
        meta: {
          title: "企业信息新增",
          showCommon: true,
        },
        component: () => import("../views/enterprise/EnterpriseRegAdd.vue"),
      },
      {
        path: "/enterprise_insured",
        name: "enterprise_insured",
        meta: {
          title: "参保管理",
          showCommon: true,
        },
        component: () => import("../views/enterprise/EnterpriseInsured.vue"),
      },
      {
        path: "/production_safety_status_monitoring",
        name: "production_safety_status_monitoring",
        meta: {
          title: "生产安全状态监控",
          showCommon: true,
        },
        component: () =>
          import("../views/enterprise/ProductionSafetyStatusMonitoring.vue"),
      },
      {
        path: "/enterprise_risk_assessment",
        name: "enterprise_risk_assessment",
        meta: {
          title: "风险评估",
          showCommon: true,
        },
        component: () =>
          import("../views/enterprise/EnterpriseRiskAssessment.vue"),
      },
    ],
  },
  //机构管理
  {
    path: "/institutions",
    name: "institutions",
    meta: {
      title: "机构管理",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "institutions_reg",
        name: "institutions_reg",
        meta: {
          title: "机构注册",
          showCommon: true,
        },
        component: () => import("../views/institutions/InstitutionsReg.vue"),
      },
      {
        path: "/institutions_reg_add",
        name: "institutions_reg_add",
        meta: {
          title: "机构注册新增",
          showCommon: true,
        },
        component: () => import("../views/institutions/InstitutionsRegAdd.vue"),
      },
      {
        path: "/institutions_audit",
        name: "institutions_audit",
        meta: {
          title: "机构审核",
          showCommon: true,
        },
        component: () => import("../views/institutions/InstitutionalAudit.vue"),
      },
      {
        path: "/institutions_audit_form",
        name: "institutions_audit_form",
        meta: {
          title: "机构审核表单",
          showCommon: true,
        },
        component: () =>
          import("../views/institutions/InstitutionalAuditForm.vue"),
      },
      {
        path: "/institutions_maintenance",
        name: "institutions_maintenance",
        meta: {
          title: "机构信息维护",
          showCommon: true,
        },
        component: () =>
          import("../views/institutions/InstitutionalMaintenance.vue"),
      },
      {
        path: "/institutions_violations",
        name: "institutions_violations",
        meta: {
          title: "违规处理",
          showCommon: true,
        },
        component: () =>
          import("../views/institutions/InstitutionalViolations.vue"),
      },
      {
        path: "/violation_handling_details",
        name: "violation_handling_details",
        meta: {
          title: "违规处理明细",
          showCommon: true,
        },
        component: () =>
          import("../views/institutions/ViolationHandlingDetails.vue"),
      },
    ],
  },
  //赔付与进度管理
  {
    path: "/compensation_and_schedule_management",
    name: "compensation_and_schedule_management",
    meta: {
      title: "赔付与进度管理",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/claim",
        name: "claim",
        meta: {
          title: "申请提交",
          showCommon: true,
        },
        component: () =>
          import("../views/compensation_and_schedule_management/Claim.vue"),
      },
      {
        path: "/progress_query",
        name: "progress_query",
        meta: {
          title: "进展查询",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/compensation_and_schedule_management/ProgressQuery.vue"
          ),
      },
      {
        path: "/result_of_compensation",
        name: "result_of_compensation",
        meta: {
          title: "赔付结果",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/compensation_and_schedule_management/ResultOfCompensation.vue"
          ),
      },
    ],
  },
  //保单服务
  {
    path: "/policy_services",
    name: "policy_services",
    meta: {
      title: "保单服务",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/customer_service",
        name: "customer_service",
        meta: {
          title: "客户服务",
          showCommon: true,
        },
        component: () => import("../views/policy_services/CustomerService.vue"),
      },
      {
        path: "/service_process_optimization",
        name: "service_process_optimization",
        meta: {
          title: "服务流程优化",
          showCommon: true,
        },
        component: () =>
          import("../views/policy_services/ServiceProcessOptimization.vue"),
      },
      {
        path: "/application_acceptance",
        name: "application_acceptance",
        meta: {
          title: "申请受理",
          showCommon: true,
        },
        component: () =>
          import("../views/policy_services/ApplicationAcceptance.vue"),
      },
      {
        path: "/risk_assessment",
        name: "risk_assessment",
        meta: {
          title: "风险评估",
          showCommon: true,
        },
        component: () => import("../views/policy_services/RiskAssessment.vue"),
      },
      {
        path: "/determination_of_underwriting_conditions",
        name: "determination_of_underwriting_conditions",
        meta: {
          title: "承保条件确定",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/policy_services/DeterminationOfUnderwritingConditions.vue"
          ),
      },
      {
        path: "/contract_signing",
        name: "contract_signing",
        meta: {
          title: "合同签订",
          showCommon: true,
        },
        component: () => import("../views/policy_services/ContractSigning.vue"),
      },
      {
        path: "/order_management",
        name: "order_management",
        meta: {
          title: "订单管理",
          showCommon: true,
        },
        component: () => import("../views/policy_services/OrderManagement.vue"),
      },
    ],
  },
  // 理赔服务
  {
    path: "/claims_services",
    name: "claims_services",
    meta: {
      title: "理赔服务",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/claims",
        name: "claims",
        meta: {
          title: "理赔申请",
          showCommon: true,
        },
        component: () =>
          import("../views/claims_services/Claims.vue"),
      },
      {
        path: "/claims_review",
        name: "claims_review",
        meta: {
          title: "索赔审核",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/claims_services/ClaimsReview.vue"
          ),
      },
      {
        path: "/payment_of_compensation",
        name: "payment_of_compensation",
        meta: {
          title: "赔偿支付",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/claims_services/PaymentOfCompensation.vue"
          ),
      },
    ],
  },

  //统计分析
  {
    path: "/statistical_analysis",
    name: "statistical_analysis",
    meta: {
      title: "统计分析",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/data_summary",
        name: "data_summary",
        meta: {
          title: "数据汇总",
          showCommon: true,
        },
        component: () =>
          import("../views/statistical_analysis/DataSummary.vue"),
      },
      {
        path: "/data_analysis",
        name: "data_analysis",
        meta: {
          title: "数据分析",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/statistical_analysis/DataAnalysis.vue"
          ),
      },
      {
        path: "/strategy_adjustment",
        name: "strategy_adjustment",
        meta: {
          title: "策略调整",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/statistical_analysis/StrategyAdjustment.vue"
          ),
      },
      {
        path: "/business_optimization",
        name: "business_optimization",
        meta: {
          title: "业务优化",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/statistical_analysis/BusinessOptimization.vue"
          ),
      },
    ],
  },

  //多维度统计分析
  {
    path: "/multi_dimensional_statistical_analysis",
    name: "multi_dimensional_statistical_analysis",
    meta: {
      title: "多维度统计分析",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/risk_assessment_model",
        name: "risk_assessment_model",
        meta: {
          title: "风险评估模型",
          showCommon: true,
        },
        component: () =>
          import("../views/multi_dimensional_statistical_analysis/RiskAssessmentModel.vue"),
      },
      {
        path: "/early_warning_system",
        name: "early_warning_system",
        meta: {
          title: "预警系统",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/multi_dimensional_statistical_analysis/EarlyWarningSystem.vue"
          ),
      },
      {
        path: "/decision_support",
        name: "decision_support",
        meta: {
          title: "决策支持",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/multi_dimensional_statistical_analysis/DecisionSupport.vue"
          ),
      },
      {
        path: "/data_acquisition",
        name: "data_acquisition",
        meta: {
          title: "数据采集",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/multi_dimensional_statistical_analysis/DataAcquisition.vue"
          ),
      },
      {
        path: "/multidimensional_data_analysis",
        name: "multidimensional_data_analysis",
        meta: {
          title: "数据分析",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/multi_dimensional_statistical_analysis/MultidimensionalDataAnalysis.vue"
          ),
      },
      {
        path: "/report_generation",
        name: "report_generation",
        meta: {
          title: "报表生成",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/multi_dimensional_statistical_analysis/ReportGeneration.vue"
          ),
      },
    ],
  },
  //全局统计分析
  {
    path: "/global_statistical_analysis",
    name: "global_statistical_analysis",
    meta: {
      title: "统计分析",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/global_data_analysis",
        name: "global_data_analysis",
        meta: {
          title: "数据分析",
          showCommon: true,
        },
        component: () =>
          import("../views/global_statistical_analysis/GlobalDataAnalysis.vue"),
      },
      {
        path: "/risk_management_recommendations",
        name: "risk_management_recommendations",
        meta: {
          title: "风险管理建议",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/global_statistical_analysis/RiskManagementRecommendations.vue"
          ),
      },
      {
        path: "/service_data_collection",
        name: "service_data_collection",
        meta: {
          title: "服务数据收集",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/global_statistical_analysis/ServiceDataCollection.vue"
          ),
      },
      {
        path: "/global_strategy_adjustment",
        name: "global_strategy_adjustment",
        meta: {
          title: "策略调整",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/global_statistical_analysis/StrategyAdjustment.vue"
          ),
      },
    ],
  },

  //客户教育
  {
    path: "/customer_education",
    name: "customer_education",
    meta: {
      title: "客户教育",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/education_curriculum",
        name: "education_curriculum",
        meta: {
          title: "教育课程",
          showCommon: true,
        },
        component: () =>
          import("../views/customer_education/EducationCurriculum.vue"),
      },
      {
        path: "/online_q_a",
        name: "online_q_a",
        meta: {
          title: "在线问答",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/customer_education/OnlineQA.vue"
          ),
      }
    ],
  },

  //系统管理
  {
    path: "/system_management",
    name: "system_management",
    meta: {
      title: "系统管理",
      showCommon: true,
    },
    component: ContentView,
    children: [
      {
        path: "/account_management",
        name: "account_management",
        meta: {
          title: "账户管理",
          showCommon: true,
        },
        component: () =>
          import("../views/system_management/AccountManagement.vue"),
      },
      {
        path: "/role_management",
        name: "role_management",
        meta: {
          title: "角色管理",
          showCommon: true,
        },
        component: () =>
          import(
            "../views/system_management/RoleManagement.vue"
          ),
      }
    ],
  },


  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});


export default router;
