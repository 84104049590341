/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-10 14:51:23
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-01-17 14:19:39
 * @FilePath: /监管/jianguan/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/theme/index.css'

import './assets/tailwind.css'

import * as echarts from 'echarts';
import '../node_modules/echarts/map/js/china';


import api from './http/api'
import http from './http/http'
import axios from './http/axios'  // 导入封装好的 axios 实例

// 在 Vue.prototype 上添加属性
Vue.prototype.$api = api;
Vue.prototype.$http = http;
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
