/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-15 14:05:20
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-01-15 14:17:47
 * @FilePath: /监管/jianguan/src/http/axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
// 请求拦截
axios.interceptors.request.use(
  (config) => {
    // 1. 这个位置就请求前最后的配置
    // 2. 当然你也可以在这个位置 加入你的后端需要的用户授权信息
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    // 请求成功
    if(response.headers.token){
      localStorage.setItem("token",response.headers.token);
    }
    if(response.data.code === 401) {
      this.$router.push("/");
    }
    return response.data;
  },
  (error) => {
    // 请求失败
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          // 对400错误您的处理\
          break;
        default:
          // 如果以上都不是的处理
          return Promise.reject(error);
      }
    }
  }
);
