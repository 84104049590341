<!--
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-10 14:51:23
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-01-13 11:29:23
 * @FilePath: /监管/jianguan/src/views/HomeView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ContentView'
}
</script>
