/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2025-01-15 14:04:21
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2025-01-21 16:01:44
 * @FilePath: /监管/jianguan/src/http/api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

const baseUrl = 'http://kj1.winmatch.cn/admin/'
export default {
  //公共接口
  Admin: {
    Login: baseUrl + "login",
  },
  Institution: {
    List: baseUrl + "institution",
    Create: baseUrl + "institution",
    Info: baseUrl + "institution",
    Update: baseUrl + "institution",
    Audit: baseUrl + "institution",
    Del: baseUrl + "institution",
    File: baseUrl + "institution/file",
    CreateViolation: baseUrl + "institution",
    ViolationList: baseUrl + "institution/violationList",
  },
  Enterprise:{
    List: baseUrl + "enterprise",
    Create: baseUrl + "enterprise",
    Info: baseUrl + "enterprise",
    Update: baseUrl + "enterprise",
    Audit: baseUrl + "enterprise",
    Del: baseUrl + "enterprise",
    Insured: baseUrl + "enterprise/insured",
  },
  SafetyManage:{
    List: baseUrl + "safetyManage",
    Update: baseUrl + "safetyManage",
    Info: baseUrl + "safetyManage",
    Create: baseUrl + "safetyManage",
    Del: baseUrl + "safetyManage",
  },
  RiskControl:{
    List: baseUrl + "riskControl",
    Update: baseUrl + "riskControl",
    Info: baseUrl + "riskControl",
    Create: baseUrl + "riskControl",
    Del: baseUrl + "riskControl",
  },
  RiskPrevent:{
    List: baseUrl + "riskPrevent",
    Update: baseUrl + "riskPrevent",
    Info: baseUrl + "riskPrevent",
    Create: baseUrl + "riskPrevent",
    Del: baseUrl + "riskPrevent",
  },
  Intermediary:{
    List: baseUrl + "intermediary",
    Update: baseUrl + "intermediary",
    Info: baseUrl + "intermediary",
    Create: baseUrl + "intermediary",
    Del: baseUrl + "intermediary",
    Audit: baseUrl + "intermediary",
    Score: baseUrl + "intermediary/score",
    Evaluate: baseUrl + "intermediary/evaluate",
  },
  BusinessAdvice:{
    List: baseUrl + "businessAdvice",
    Create: baseUrl + "businessAdvice",
    Info: baseUrl + "businessAdvice",
    Update: baseUrl + "businessAdvice",
    Del: baseUrl + "businessAdvice",
    Score: baseUrl + "businessAdvice/score",
  },
  Role:{
    List: baseUrl + "role",
    Create: baseUrl + "role",
    Info: baseUrl + "role",
    Update: baseUrl + "role",
    Del: baseUrl + "role",
    PermissionList: baseUrl + "role/permission/list",
  },
  Account:{
    List: baseUrl + "account",
    Create: baseUrl + "account",
    Info: baseUrl + "account",
    Update: baseUrl + "account",
    Del: baseUrl + "account",
  },
}
